import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"

import { useSelector } from "react-redux"


const FourFour = () => {
  const { loggedIn, ready } = useSelector(state => state.db)


  if (ready && loggedIn) {
    return (
      <Layout>
        <SEO title="404 Not Found" />
        <Container component="main" maxWidth="md">
          <Typography component="h1" variant="h4">
          404 Not Found
          </Typography>
          <Typography component="p" variant="body1">
            We cannot find the page you looking for, head back to the home page or contact us for help
          </Typography>
        </Container>
      </Layout>
    )
  } else {
    return (
      <Layout>
        <SEO title="404 Not Found" />
        <Container component="main" maxWidth="md">
          <Typography component="h1" variant="h4">
          404 Not Found
          </Typography>
          <Typography component="p" variant="body1">
            We cannot find the page you looking for, head back to the home page or contact us for help
          </Typography>
        </Container>
      </Layout>
    )
  }
}

export default FourFour
